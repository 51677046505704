<template>
  <er-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    @beforeClose="handleCloseDialog"
    @open="handleOpenDialog"
    :title="`${$t('Comn_update')} ${$t('Comn_shrimptalk')}`"
    class="shrimp-talk-container"
    custom-class="shrimptalk_action_dialog"
  >
    <ValidationObserver ref="dialogListForm">
      <el-form
        size="small"
        v-loading="loading"
        :model="shrimpTalkForm"
        ref="shrimpTalkForm"
        class="demo-shrimpTalkForm"
      >
        <ValidationProvider
          rules="required"
          :name="$tc('Comn_pond_name', 0)"
          v-slot="{ errors }"
        >
          <el-form-item
            :label="$tc('Comn_pond_name', 0)"
            prop="pond"
            :error="errors[0]"
          >
            <er-select
              v-model="shrimpTalkForm.pond"
              :placeholder="$tc('Comn_pond_name', 0)"
              collapse-tags
              value-key="title"
              :reserve-keyword="true"
              multiple
              filterable
              size="small"
              @change="handleChangePond"
            >
              <template v-for="pond in pondsData">
                <el-option
                  v-if="pond.shrimp_talks.length > 0"
                  :key="pond._id"
                  :label="pond.title"
                  :value="pond._id"
                ></el-option>
              </template>
            </er-select>
          </el-form-item>
        </ValidationProvider>
        <ValidationProvider
          rules="required"
          :name="$t('Comn_shrimp_talk_code')"
          v-slot="{ errors }"
        >
          <el-form-item
            :label="$t('Comn_shrimp_talk_code')"
            prop="shrimp_talk_id"
            :error="errors[0]"
          >
            <er-select
              v-model="shrimpTalkForm.shrimp_talk_id"
              :placeholder="$t('Comn_shrimp_talk_code')"
              collapse-tags
              value-key="code"
              :reserve-keyword="true"
              :disabled="disable_field"
              multiple
              filterable
              size="small"
              @change="handleChangeShrimpTalk"
            >
              <el-option
                v-for="st in selectedPondSTs"
                :key="st._id"
                :value="st"
                :label="upm__getValue(st)"
              >
              </el-option>
            </er-select>
          </el-form-item>
        </ValidationProvider>

        <ValidationProvider
          :name="$t('PM_day_feed')"
          :rules="{
            required_if_not: $t('Comn_feed_level'),
            max_value: this.upm__feedUnits === 'lb' ? 441 : 400,
            min_value: this.upm__feedUnits === 'lb' ? 44 : 20
          }"
          immediate
          v-slot="{ errors }"
        >
          <el-form-item
            :label="$t('PM_day_feed')"
            prop="feedlimit"
            :error="errors[0]"
          >
            <el-input
              v-model="shrimpTalkForm.feed_limit"
              @input="handlefeedlimit"
              :disabled="disable_field"
              size="small"
              :controls="false"
              :placeholder="$t('PM_day_feed')"
            ></el-input>
          </el-form-item>
        </ValidationProvider>

        <ValidationProvider
          :name="$t('Comn_feed_level')"
          immediate
          :rules="{
            required_if_not: $t('PM_day_feed')
          }"
          v-slot="{ errors }"
        >
          <el-form-item
            :label="$t('Comn_feed_level')"
            prop="feeding_level"
            :error="errors[0]"
          >
            <er-select
              v-model="shrimpTalkForm.feeding_level"
              @input="handlefeedlevel"
              :placeholder="$t('Comn_feed_level')"
              :disabled="disableFeedingLevel || disable_field"
              size="small"
            >
              <el-option
                v-for="level in feedingLevelData"
                :key="level.value"
                :label="$t(level.label)"
                :value="level.value"
              ></el-option>
            </er-select>
          </el-form-item>
        </ValidationProvider>
        <div
          class="errorwithouthighlight"
          v-bind:class="{ errorCss: errorhighlight }"
        >
          <span class="material-icons-outlined"> info </span>&nbsp;
          {{ $t("Atleast_feed_limit_feed_level_is_required") }}
        </div>
      </el-form>
    </ValidationObserver>
    <layout-toolbar slot="footer" justify="center">
      <er-button
        size="mini"
        btnType="save"
        :showLabel="true"
        :showIcon="true"
        @click="submitResourceDetails"
        :loading="loading"
        :disabled="isReadOnly || disable_field"
      ></er-button>
      <er-button
        size="mini"
        btnType="cancel"
        :showLabel="true"
        @click="handleCloseDialog"
        :disabled="isReadOnly"
      ></er-button>
    </layout-toolbar>
  </er-dialog>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import { mapGetters, mapActions } from "vuex";
import { extend } from "vee-validate";
import {
  required,
  max_value,
  min_value,
  required_if
} from "vee-validate/dist/rules";
// extend("required", {
//   ...required,
//   message: "{_field_} is required",
// });
// import { versionCompare } from "@/utils/comparators.js";

export default {
  mixins: [errorHandlerMixin, errorKeyMapMixin],
  props: {
    showDialog: {
      default: false
    },
    actionToPerform: {
      default: ""
    },
    stPmModeData: {
      default: {}
    }
  },
  data: function() {
    return {
      checkFeedLevel: false,
      checkFeedLimit: false,
      loading: false,
      disable_field: false,
      errorhighlight: false,
      disable: false,
      shrimpTalkForm: {
        pond: [],
        shrimp_talk_id: [],
        feed_limit: "",
        feeding_level: "",
        title: ""
      },
      pickerOptions: {
        format: "HH:mm"
      },
      feedingLevel: [
        {
          label: "Comn_normal",
          value: 1
        },
        {
          label: "Comn_economical",
          value: 2
        },
        {
          label: "Comn_aggressive",
          value: 3
        }
        // {
        //   label: "Comn_super_aggressive",
        //   value: 4
        // }
      ],
      feedingLevelSuper: [
        {
          label: "Comn_normal",
          value: 1
        },
        {
          label: "Comn_economical",
          value: 2
        },
        {
          label: "Comn_aggressive",
          value: 3
        },
        {
          label: "Comn_super_aggressive",
          value: 4
        }
      ],
      shouldSuperAggressiveDisplay: false,
      disableFeedingLevel: false
    };
  },
  computed: {
    ...mapGetters("user", {
      isReadOnly: "isReadOnly",
      getPreferredUnits: "getPreferredUnits"
    }),
    ...mapGetters({
      PondMothers: "pondmother/getPondMothers",
      mapPondIdPond: "pond/getMapPondidPond",
      Ponds: "pond/getPonds",
      ShrimpTalks: "shrimptalk/getShrimpTalks",
      getUserId: "user/getUserId"
    }),
    pondsData() {
      return this.Ponds;
    },
    feedMaxValue() {
      if (this.upm__feedUnits === "lb") {
        return 441;
      } else {
        return 400;
      }
    },
    feedMinValue() {
      if (this.upm__feedUnits === "lb") {
        return 44;
      } else {
        return 20;
      }
    },

    dialogVisible: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.handleCloseDialog("close");
      }
    },
    getDeviceName() {
      if (this.getPreferredUnits.device_name === "code") {
        return "code";
      } else {
        return "title";
      }
    },

    selectedPondSTs: function() {
      const pondSTs = this.stArray();

      if (this.getDeviceName === "title") {
        return pondSTs.sort((a, b) =>
          this.$commonUtils.alphaNumericComparator(a.title, b.title)
        );
      } else {
        return pondSTs.sort((a, b) =>
          this.$commonUtils.alphaNumericComparator(a.stcode, b.stcode)
        );
      }
    },
    feedingLevelData() {
      const superAggressiveEnabledUsers = [
        "5f74984140f3e1412d1de9cd",
        "5e5cfb322c599d13d25b607e",
        "5f098445be50fddb616e47c9"
      ]
      if (superAggressiveEnabledUsers.includes(this.getUserId)) {
        return this.feedingLevelSuper;
      } else {
        return this.feedingLevel;
      }
    }
  },
  mounted() {
    this.initValidationStrings();
    this.upm__setDataKeys("stcode", "title");

    console.log("ShrimpTalks", this.ShrimpTalks);
  },
  methods: {
    ...mapActions({
      updateShrimpTalkDetails: "shrimptalk/updateShrimpTalkDetails"
    }),
    getStObject(id) {
      return this.ShrimpTalks.filter((item) => item.code === id);
    },
    stArray() {
      const sts = this.shrimpTalkForm.pond
        .map((pond) => this.mapPondIdPond.get(pond).shrimp_talks)
        .flat(1);
      return sts.map((x) => {
        const stcode = x.code;
        return {
          code: stcode,
          stcode: stcode,
          title: x.title || stcode,
          _id: x._id
        };
      });
    },
    handlefeedlimit(event) {
      if (event !== "") {
        this.checkFeedLimit = true;
        this.errorhighlight = false;
      } else {
        this.checkFeedLimit = false;
        if (this.checkFeedLevel) {
          this.errorhighlight = false;
        } else {
          this.errorhighlight = true;
        }
      }
    },
    handlefeedlevel(event) {
      if (event + 1) {
        this.checkFeedLevel = true;
      }
      this.errorhighlight = false;
    },

    handleOpenDialog() {
      this.disable_field = true;
      this.checkFeedLevel = false;
      this.checkFeedLimit = false;
      this.errorhighlight = false;
      this.shrimpTalkForm = {
        pond: [],
        shrimp_talk_id: [],
        feed_limit: "",
        feeding_level: "",
        title: ""
      };
      this.initValidationStrings();
      this.$nextTick(() => {
        this.$refs.dialogListForm.reset();
      });
    },
    handleCloseDialog(event) {
      this.shrimpTalkForm = {
        pond: [],
        shrimp_talk_id: [],
        feed_limit: "",
        feeding_level: "",
        title: ""
      };
      this.$refs.dialogListForm.reset();
      this.$emit("close_dialog", event);
    },
    handleChangeShrimpTalk(pmIdArr) {
      if (pmIdArr.length === 0) {
        this.handleCheckAllSTs(false);
      }
    },
    handleChangePond(pondIdArr) {
      if (pondIdArr.length > 0) {
        this.handleCheckAllSTs(true);
        this.disable_field = false;
      } else {
        this.handleCheckAllSTs(false);
      }
    },
    handleCheckAllSTs(value) {
      this.disableFeedingLevel = false;
      this.shrimpTalkForm.shrimp_talk_id = value ? this.selectedPondSTs : [];
      if (value === true) {
        this.selectedPondSTs.map(stId => {
          if (this.stPmModeData[stId._id] === true) {
            this.disableFeedingLevel = true;
            this.shrimpTalkForm.feeding_level = '';
          }
        })
      }
    },
    async submitResourceDetails() {
      const response = await this.$refs.dialogListForm.validate();

      if (!response) {
        if (
          this.shrimpTalkForm.feed_limit !== "" ||
          this.shrimpTalkForm.feeding_level !== ""
        ) {
          this.errorhighlight = false;
          return "";
        } else {
          this.errorhighlight = true;
          return "";
        }
      } else if (
        this.shrimpTalkForm.feed_limit === "" &&
        this.shrimpTalkForm.feeding_level === ""
      ) {
        this.errorhighlight = true;
      }

      this.loading = true;
      const formattedST = this.shrimpTalkForm.shrimp_talk_id.map((st) => {
        return [
          [this.checkFeedLevel, "feeding_level", "feeding_level"],
          [this.checkFeedLimit, "feed_limit", "feed_limit"]
        ].reduce(
          (acc, [isChecked, destinationProp, sourceProp]) => {
            if (isChecked && sourceProp === "feeding_level") {
              acc[destinationProp] = this.shrimpTalkForm[sourceProp] - 1;
            } else if (isChecked && sourceProp === "feed_limit") {
              acc[destinationProp] =
                this.upm__feedUnits === "lb"
                  ? Math.round(Number(this.shrimpTalkForm[sourceProp]) / 2.2)
                  : Number(this.shrimpTalkForm[sourceProp]);
            }
            return acc;
          },
          { shrimp_talk_id: st._id }
        );
      });

      try {
        await this.updateShrimpTalkDetails(formattedST);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Shrimptalk_settings_updated"),
          duration: 5000,
          type: "success"
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.$emit("close_dialog", false, "refreshComponent");
        this.loading = false;
      }
    },

    initValidationStrings() {
      extend("required_if_not", {
        ...required_if,
        // params: ['target']

        validate: (value, args) => {
          const target_value = args.target;
          return Boolean(target_value || value);
        },
        message: (fieldName) => {
          return this.$t('Comn_field_is_required', {
              field: fieldName
            })
          }
      });

      extend("required", {
        ...required,
        message: this.$t("Comn_field_is_required", {
          field: "{_field_}"
        })
      });
      extend("max_value", {
        ...max_value,
        message: this.$tc("Comn_must_less_than_or_equal", 1, {
          field: "{_field_}",
          threshold: this.feedMaxValue
        })
      });
      extend("min_value", {
        ...min_value,
        message: this.$tc("Comn_must_greater_than_or_equal", 1, {
          field: "{_field_}",
          threshold: this.feedMinValue
        })
      });
    }
  }
};
</script>

<style lang="scss">
.shrimp-talk-container {
  .el-dialog {
    @include responsiveProperty(width, 36%, 36%, 36%);
  }
  .el-dialog__body {
    padding: 15px 15px;
    // height: 400px;
  }
  .el-dialog .el-dialog__body {
    @include responsiveProperty(height, 210px, 210px, 250px);
  }
  .select-units,
  .select-resource-type {
    width: 100%;
  }
  // .el-input-number {
  //   width: 100%;
  // }

  .el-select .el-tag.el-tag--info .el-tag__close {
    display: inline-block !important;
    pointer-events: all !important;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    // width: none;

    // min-width: 0;
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    width: 275px !important;
  }
  .el-input--mini {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    width: 275px;
  }
}

.shrimptalk_action_dialog {
  border-radius: 10px;
  .el-select__tags-text {
    color: #909399 !important;
    background-color: #f2f0f9;
  }

  .errorwithouthighlight {
    display: flex;
    align-items: center;
    justify-content: center;
    @include responsiveProperty(font-size, $font_size_1, $font_size_2, 16px);
  }

  .errorCss {
    color: #f56c6c;
    display: flex;
    align-items: center;
    justify-content: center;
    @include responsiveProperty(font-size, $font_size_1, $font_size_2, 16px);
  }
  background: #fcfcfc;
  border-radius: 10px;

  .el-input--small .el-input__inner {
    @include responsiveProperty(width, 225px, 250px, 280px);
  }

  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    @include responsiveProperty(min-width, 143px, 170px, 210px);
  }
}
</style>
