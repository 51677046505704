<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: feedTemplate.vue
Description: This file contains UI components used in Feed Template
-->
<template>
  <div
    class="feed-template-list"
    v-loading="loading"
    element-loading-background="white"
  >
    <el-alert
      ref="error_alert"
      v-if="ehm__unhandledErrorMessage !== ''"
      :title="ehm__unhandledErrorMessage"
      type="error"
      @close="ehm__clearErrorMessages"
    ></el-alert>
    <el-row type="flex">
      <el-col class="feed-temp-list-section">
        <el-row v-if="Object.keys(existingFTs).length > 0">
          <el-row
            v-for="value in existingFTs"
            :key="value.key"
            class="feed-template-list-container"
          >
            <el-row
              type="flex"
              justify="space-between"
              align="center"
              style="margin-bottom: 10px"
            >
              <div class="set-title-container">
                <svg
                    :class="'list-item__color-swatch'"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="7.5"
                      cy="7.5"
                      r="7.5"
                      :fill="getFTMode(value).color"
                    >
                      <title>{{ getFTMode(value).translation }}</title>
                    </circle>
                  </svg>

                <span class="title">{{ value.title }}</span>
              </div>
              <div>
                <er-button
                  size="mini"
                  btnType="editSolid"
                  :disabled="isReadOnly"
                  :showLabel="true"
                  :showIcon="true"
                  @click="handleChangeFT(value._id)"
                ></er-button>
                <er-button
                  size="mini"
                  btnType="deleteForever"
                  :showLabel="true"
                  :showIcon="true"
                  @click="deleteTemplate(value._id)"
                  :disabled="isReadOnly"
                ></er-button>
              </div>
            </el-row>
            <el-row class="sets-data">
              <table
                class="el-table el-table--fit el-table--enable-row-hover el-table--enable-row-transition"
              >
                <thead>
                  <tr class="el-table__row">
                    <th class="is-leaf header-vertical" style="width: 200px">
                      {{ tableRowsNames.particulars }}
                    </th>
                    <th
                      class="is-leaf"
                      v-for="i in 8"
                      :key="i"
                      style="width: 110px"
                    >
                      <span>{{
                        ftm__formatEmpty(
                          getValueIfTSIsAvailable(value, i, "index")
                        )
                      }}</span>
                    </th>
                  </tr>
                </thead>
                <tr class="el-table__row" v-if="isHybridTemplate(value)">
                  <th class="is-leaf header-vertical">
                    {{ tableRowsNames.mode }}
                  </th>
                  <td v-for="i in 8" :key="i">
                    {{
                      ftm__formatEmpty(
                        getValueIfTSIsAvailable(value, i, "mode")
                      )
                    }}
                  </td>
                </tr>
                <tr class="el-table__row">
                  <th class="is-leaf header-vertical">
                    {{ tableRowsNames.s_time }}
                  </th>
                  <td v-for="i in 8" :key="i">
                    {{
                      ftm__formatEmpty(
                        getValueIfTSIsAvailable(value, i, "s_time")
                      )
                    }}
                  </td>
                </tr>
                <tr class="el-table__row">
                  <th class="is-leaf header-vertical">
                    {{ tableRowsNames.e_time }}
                  </th>
                  <td v-for="i in 8" :key="i">
                    {{
                      ftm__formatEmpty(
                        getValueIfTSIsAvailable(value, i, "e_time")
                      )
                    }}
                  </td>
                </tr>
                <tr class="el-table__row">
                  <th class="is-leaf header-vertical">
                    {{ tableRowsNames.feed_percentage }}
                  </th>
                  <td v-for="i in 8" :key="i">
                    {{
                      ftm__formatEmpty(
                        getValueIfTSIsAvailable(value, i, "feed_percentage")
                      )
                    }}
                  </td>
                </tr>
                <tr class="el-table__row" v-if="isHybridTemplate(value)">
                  <th class="is-leaf header-vertical">
                    {{ tableRowsNames.feeding_level }}
                  </th>
                  <td v-for="i in 8" :key="i">
                    {{
                      ftm__formatEmpty(
                        getValueIfTSIsAvailable(value, i, "feeding_level")
                      )
                    }}
                  </td>
                </tr>
              </table>
            </el-row>
          </el-row>
        </el-row>
        <el-row v-else class="no-feed-template-section">
          <template v-if="!tempLoading">
            <el-col><img src="@/assets/settings/inbox.svg" /></el-col>
            <el-col>
              <p>{{ $t("Comn_no_sets_available") }}</p>
            </el-col>
          </template>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import { mapGetters, mapActions } from "vuex";
import filtersMixin from "../../mixins/filtersMixin";
import { generateColorForFTType } from "../../utils/componentColors";

export default {
  name: "FeedTemplate",
  mixins: [errorHandlerMixin, datesHandlerMixin, filtersMixin],
  props: {
    tabData: {
      default: {},
    },
    location: {
      default: {},
    },
    tempLoading: {
      default: true,
    },
  },
  data: function () {
    return {
      feedTemplate: {},
      editFeedTempId: "",
      errorMessage: "",
      loading: false,
      feedpercentagearray: [],
      timeslotsarray: [],
      feedingLevel: [
        {
          lang_key: "Comn_na",
          value: -1
        },
        {
          lang_key: "Comn_normal",
          value: 0,
        },
        {
          lang_key: "Comn_economical",
          value: 1,
        },
        {
          lang_key: "Comn_aggressive",
          value: 2,
        },
        {
          lang_key: "Comn_super_aggressive",
          value: 3,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("user", {
      isReadOnly: "isReadOnly",
    }),
    existingFTs() {
      return this.$store.getters["schedules/getMapftIdFT"];
    },
    availableTSModes() {
      return {
        AUTOMATIC: { mode: "AUTOMATIC", lang_key: "PM_automatic" },
        SCHEDULE: { mode: "SCHEDULE", lang_key: "Comn_schedule" },
      };
    },
    feedingLevelMap() {
      return this.feedingLevel.reduce((acc, curr) => {
        acc[curr.value] = curr
        return acc;
      }, {})
    },
    scrollBarWrapClass() {
      if (Object.values(this.existingFTs).length > 0) {
        return "feed-template__warp";
      } else {
        return "feed-template__warp-no-text";
      }
    },
    selectedFTId: {
      get() {
        const templateId =
          this.$store.getters["schedules/getCurrentFeedTemplateId"];
        return templateId;
      },
      async set(templateId) {
        this.loading = true;
        await this.$store.dispatch(
          "schedules/setCurrentFeedTemplateId",
          templateId
        );
        this.loading = false;
      },
    },
    tableRowsNames() {
      return {
        particulars: this.$t("Comn_schedules"),
        mode: this.$t("mode"),
        s_time: this.$t("PM_start_time"),
        e_time: this.$t("PM_end_time"),
        feed_percentage: this.$t("feed_ratio_%"),
        feeding_level: this.$t("Comn_feeding_level"),
      };
    },
  },
  async mounted() {},
  methods: {
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator",
    }),
    getFTMode(feedTemplate) {
      const type = feedTemplate.type;
      return generateColorForFTType(type);
    },
    isHybridTemplate: function (feedTemplate) {
      return feedTemplate.type === "HYBRID";
    },
    getValueIfTSIsAvailable: function (feedTemplate, currTSIndex, key) {
      if (currTSIndex > feedTemplate.timeSlots.length) {
        return null;
      }
      if (key === "index") {
        return `S${currTSIndex}`;
      }
      if (key === "feed_percentage") {
        return `${feedTemplate.timeSlots[currTSIndex - 1][key]}%`;
      }
      if (key === "mode") {
        return this.$t(this.availableTSModes[feedTemplate.timeSlots[currTSIndex - 1][key]].lang_key)
      }
      if (key === "feeding_level") {
        return this.$t(this.feedingLevelMap[feedTemplate.timeSlots[currTSIndex - 1][key]].lang_key)
      }
      return feedTemplate.timeSlots[currTSIndex - 1][key];
    },
    initComponent() {
      this.loading = true;
      this.$emit("initComponentData");
      this.loading = false;
    },
    handleChangeFT(feedTemplateId) {
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        this.$emit(
          "handleEditTemplate",
          this.$lodash.cloneDeep(this.existingFTs[feedTemplateId]),
          "EDIT"
        );
      } catch (error) {
        this.ehm__errorMessages(error, true);
      }
    },
    handleFeedChange(feedValue, indexTimeSlot) {
      const currTS = this.feedTemplate.timeSlots[indexTimeSlot];
      if (feedValue === "") {
        currTS.feed_percentage = +feedValue;
      } else if (feedValue < 0) {
        currTS.feed_percentage = 0;
      } else if (feedValue > 100) {
        currTS.feed_percentage = 100;
      } else {
        currTS.feed_percentage = +feedValue;
      }
    },
    handleTimeChange(timeSelected, indexTimeSlot) {
      const currTS = this.feedTemplate.timeSlots[indexTimeSlot];
      currTS.s_time_secs = this.$commonUtils.timeStrHHmmVal(currTS.s_time);
      currTS.e_time_secs = this.$commonUtils.timeStrHHmmVal(currTS.e_time);
    },
    async deleteTemplate(id) {
      try {
        this.$gblUAMCanUserDelete(this.tabData);
        await this.$confirm(
          this.$t("do_you_want_to_delete_set"),
          this.$t("Comn_delete"),
          {
            confirmButtonText: this.$t("Comn_delete"),
            cancelButtonText: this.$t("Comn_cancel"),
            type: "warning",
            center: true,
          }
        );
        this.loading = true;
        await this.$store.dispatch("schedules/deleteFeedTemplate", id);
        this.mixPanelEventGenerator({
          eventName:
            "Settings - Schedules - Schedules One Time Settings - Delete",
        });
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("PM_set_deleted_successfully"),
          duration: 5000,
          type: "success",
        });
        this.loading = false;
      } catch (err) {
        // console.log(err);
        if (err.response) {
          this.$notify({
            title: this.$t("failed"),
            message: this.$t("Something_went_wrong"),
            duration: 5000,
            type: "error",
          });
        } else {
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = false;
        console.log(this.loading);
      }
    },
  },
};
</script>

<style lang="scss">
.feed-template-list {
  background: white;
  border-top: 1px solid #dddddd;

  .feed-temp-list-section {
    overflow-y: scroll;
    @include responsiveProperty(height, 466px, 750px, 900px);
    justify-content: center;
    padding: 22px;

    .feed-template-list-container {
      & + .feed-template-list-container {
        margin-top: 24px;
      }

      .set-title-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #0a2463;
        opacity: 0.8;
        @include responsiveProperty(
          font-size,
          $app_font_size_1,
          $app_font_size_2,
          $app_font_size_3
        );

        .list-item__color-swatch {
          height: 15px;
          width: 15px;

          & + .title {
            margin-left: 5px;
          }
        }
      }

      .sets-data {
        .el-table {
          background: #fdfdfd;
          border: 1px solid #dddddd;
          box-sizing: border-box;
          border-collapse: separate;
          border-spacing: 0px;
        }

        .el-table td,
        .el-table th.is-leaf {
          border-bottom: 1px solid #ebeef5;
          text-align: left;
          padding: 10px 5px;
        }

        .el-table th.is-leaf {
          color: #0a2463;
          background: #fdfdfd;

          &.header-vertical {
            text-align: right;
            padding-right: 20px;
          }
        }

        .el-table tr {
          background-color: transparent;
        }
      }
    }

    .no-feed-template-section {
      text-align: center;
    }
  }
}
</style>
