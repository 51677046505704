<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: scheduleSettings.vue
Description: This file contaains the UI components of schedule page in settings. It is the parent component of add sets, assign set to ponds and assign resources to ponds tabs
-->
<template>
  <el-row
    class="schedules-container"
    v-loading="loading"
    element-loading-background="white"
  >
    <el-row v-if="!loading && Ponds.length > 0">
      <layout-toolbar class="in-page-nav" v-show="!loading">
        <el-tabs v-model="activeName">
          <el-tab-pane
            :label="$t('Schedules_one_time_settings')"
            name="feedTemplate"
          ></el-tab-pane>
          <el-tab-pane
            :label="$t('PM_asing_set_pond')"
            name="assignSetsToPonds"
          ></el-tab-pane>
          <el-tab-pane
            :label="$t('PM_assign_resource_Pond')"
            name="assignPondsAndResources"
          ></el-tab-pane>
        </el-tabs>
        <div class="filler"></div>
        <er-button
            :showIcon="true"
            :title="$t('PM_create_new_set')"
            btnType="add"
            @click="() => handleCommand(getNewFeedTemplate(), 'CREATE')"
          >
            <span>{{ `${$t("PM_create_new_set")}` }}</span>
          </er-button>
      </layout-toolbar>
      <component
        :is="activeName"
        :location="getCurrUserLocation"
        :tabData="tabData"
        :tempLoading="loading"
        @initComponentData="initComponent"
        @handleEditTemplate="handleCommand"
      >
      </component>
    </el-row>
    <er-no-ponds v-else-if="!loading && Ponds.length === 0" :tabData="tabData" :disabled="isReadOnly"/>
    <Loader v-else></Loader>
    <feed-template-action-dialog
      :tabData="tabData"
      :dialogVisible="dialogVisible"
      :action="currentAction"
      :feedTemplate="feedTemplate"
      @closeDialog="handleCloseDialog"
    ></feed-template-action-dialog>
  </el-row>
</template>
<script>
import { mapGetters } from "vuex";
import feedTemplate from "../schedules/feedTemplate";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import assignPondsAndResources from "../pond/assignPondsAndResources";
import assignSetsToPonds from "../pond/assignSetsToPonds";
import Loader from "@/components/base/Loader";
import FeedTemplate from "../../model/feedtemplate";
import FeedTemplateActionDialog from "../schedules/feedTemplateActionDialog.vue";

export default {
  mixins: [errorHandlerMixin],
  components: {
    feedTemplate,
    assignPondsAndResources,
    assignSetsToPonds,
    FeedTemplateActionDialog,
    Loader,
  },
  data: function () {
    return {
      loading: true,
      query: {
        limit: 100,
        page: 1,
        location: null,
        include: null,
        exclude: null,
        order_by: null,
        order_type: null,
        un_assigned_only: false,
        created_at: null,
        created_before: null,
        created_after: null,
        updated_at: null,
        updated_before: null,
        updated_after: null,
      },
      activeName: "feedTemplate",
      dialogVisible: false,
      templateDialog: null,
      feedTemplate: null,
      currentAction: "CREATE",
    };
  },
  props: {
    tabData: {
      default: {},
    },
  },
  computed: {
    ...mapGetters("user", {
      isReadOnly: "isReadOnly",
    }),
    ...mapGetters("pond", {
      Ponds: "getPonds",
    }),
    getCurrUserLocation() {
      return this.$store.getters["user/getCurrUserLocation"];
    },
  },
  async mounted() {
    await this.initComponent();
  },
  methods: {
    initComponent: async function () {
      this.loading = true;
      try {
        if (this.getCurrUserLocation._id !== "") {
          await this.$store.dispatch("pond/fetchAllPonds", {
            location_id: this.getCurrUserLocation._id,
            include: ["title"],
            status: ["ACTIVE", "INACTIVE"],
            get_all: true,
          });
          await this.$store.dispatch("schedules/fetchAllFeedTemplates", {
            location_id: this.getCurrUserLocation._id,
            get_all: true,
          });
          await this.$store.dispatch("resource/fetchAllResources", {
            location_id: this.getCurrUserLocation._id,
            type: "FEED",
            get_all: true,
          });
        }
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    getNewFeedTemplate() {
      return FeedTemplate.getHybridSampleFT(this.getCurrUserLocation._id);
    },
    async handleCloseDialog({ refetch }) {
      this.dialogVisible = false
      if (refetch) {
        await this.initComponent()
      }
    },
    handleCommand(template, action) {
      this.feedTemplate = template;
      this.currentAction = action;
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss">
.schedules-container {
  position: relative;
  .new-btn-container {
    padding: 10px;
  }

  .feedTemplate .el-table {
    margin: 0px;
  }

  .assign-ponds-and-resources {
    padding-bottom: 10px;
  }

  .in-page-nav {
    margin: 5px;

    .el-tabs__header {
      margin: unset;
    }

    .el-tabs__nav-wrap::after {
      display: none;
    }

    .el-tabs__active-bar {
      background-color: #0a2463;
      display: none;
    }

    .el-tabs__item.is-active {
      color: white;
      opacity: 1;
      font-weight: 600;
      background: #155dbe;
      border-radius: 3px;
    }

    .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
      // padding-left: unset;
      padding: 0 20px;
    }

    .el-tabs--top .el-tabs__item.is-top:last-child {
      padding: 0 20px;
    }

    .el-tabs__nav-scroll {
      background: white;
      padding: 10px;
    }
  }

  .el-tabs__item {
    text-align: center;
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
    line-height: 30px !important;
    height: 30px !important;
  }

  .er-button.el-button--default:not(.is-circle) {
    padding: 5px 15px;
  }
}

.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #fff;
  background-color: #1966a1;
  border-color: #1966a1;
}
</style>
