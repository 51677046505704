<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: shrimpTalkSettings.vue
Description: This file contains UI components of shrimptalk settings. It is the parent component of shrimptalk settings table
-->

<template>
  <el-row
    v-loading="loading"
    element-loading-background="white"
    justify="center"
    class="shrimp-talk-settings-pondlogs"
  >
    <el-row v-if="!loading && Ponds.length > 0">
      <el-alert
        ref="error_alert"
        v-if="ehm__unhandledErrorMessage !== ''"
        :title="ehm__unhandledErrorMessage"
        type="error"
      ></el-alert>

      <el-row class="st-settings-table">
        <layout-toolbar style="margin: 10px 10px" align="middle">
          <div class="legend-row__item" v-show="!loading">
            <div :class="`color-indicator`">&nbsp;</div>
            <div class="legend-row__label">
              {{ $t("PM_main_pm") }}
            </div>
          </div>
          <div>
            <er-input
              inputType="search"
              size="mini"
              v-model="search"
              v-show="!loading"
              suffix-icon="el-icon-search"
              :placeholder="$t('Comn_search')"
              @change="handelSearchChange"
            ></er-input>
          </div>
          <er-button
            size="mini"
            btnType="save"
            :showLabel="true"
            :showIcon="true"
            :disabled="isReadOnly"
            :loading="loading"
            v-show="!loading"
            @click="submitUpdatedDetails"
          ></er-button>
          <er-button
            size="mini"
            btnType="cancel"
            :showLabel="true"
            :disabled="isReadOnly"
            :loading="loading"
            v-show="!loading"
            @click="loadSTList"
          ></er-button>

          <er-column-selector
            :columns="computedColumns"
            keyToStoreDefaults="shrimp_talk_settings"
            :title="ftm__capitalize($t('columns'))"
            @change-selection="handleChangeColumnSelection"
            @column-save="handelSaveColumns"
          ></er-column-selector>

          <div @click="handleOpenDialogofST">
            <span class="material-icons-outlined action-icon"> queue </span>
          </div>
          <er-dropdown
            placement="bottom"
            @item-click="handleTableDataDownload"
            trigger="click"
            class="highlight"
            :listOfItems="['Comn_download_as_excel', 'Comn_download_as_pdf']"
          >
            <template class="el-dropdown-link download_button" slot="suffix">
              <span class="material-icons-outlined action-icon">
                file_download
              </span>
            </template>
            <template v-slot:item-title="{ item }">{{
              $t(item.value)
            }}</template>
          </er-dropdown>
        </layout-toolbar>
        <el-row v-loading="loading" element-loading-background="white">
          <er-data-tables
            ref="tableST"
            uniqueId="shrimp-talk-settings"
            :merge="{ startColumnNumber: 1, endColumnNumber: 1 }"
            v-show="!loading"
            :columns="selectedHeaders"
            :selectedColumnHeaders="selectedColumns"
            :selectedColumnHeadersSortable="true"
            :key="$i18n.locale"
            :tableData="dataValues"
            :elTableProps="tableProps"
            :pagination-slot="true"
            :sortMethodForColumns="sortMethods"
            type="white-header-table"
            :action-column="false"
            :searchOnTableData="search"
          >
            <template v-slot:pond_title_header="{ header }">
              <span>{{ $t(header.label) }}</span>
            </template>
            <template v-slot:code_header="{ header }">
              <span>{{ $t(header.label) }}</span>
            </template>
            <template v-slot:ip_address_header="{ header }">
              <span>{{ $t(header.label) }}</span>
            </template>
            <template v-slot:wifi_signal_rssi_header="{ header }">
              <span>{{ $t(header.label) }}</span>
            </template>
            <template v-slot:wifi_connection_header="{ header }">
              <span>{{ $t(header.label) }}</span>
            </template>
            <template v-slot:title_header="{ header }">
              <span>{{ $t(header.label) }}</span>
            </template>
            <template v-slot:pond_mothers_header="{ header }">
              <span>{{ $t(header.label) }}</span>
            </template>
            <template v-slot:mac_address_header="{ header }">
              <span>{{ $t(header.label) }}</span>
            </template>
            <template v-slot:start_time_header="{ header }">
              <span>{{ $t(header.label) }}</span>
            </template>
            <template v-slot:end_time_header="{ header }">
              <span>{{ $t(header.label) }}</span>
            </template>
            <template v-slot:pause_start_header="{ header }">
              <span>{{ $t(header.label) }}</span>
            </template>
            <template v-slot:pause_end_header="{ header }">
              <span>{{ $t(header.label) }}</span>
            </template>
            <template v-slot:feed_limit_header="{ header }">
              <span>{{ $t(header.label) }}</span>
            </template>
            <template v-slot:feeding_level_header="{ header }">
              <span>{{ $t(header.label) }}</span>
            </template>

            <template v-slot:pond_title="{ row }">
              <er-highlight-search-component
                :highlightStrings="row.data.highlightStrings"
                :individualRowData="row.data"
                keyName="pond_title"
                :withoutHighlightStrings="row.data.pond_title"
              />
            </template>
            <template v-slot:code="{ row }">
              <el-row type="flex">
                <el-tooltip
                  :content="
                    getDeviceLastCommunicationColorString(row.data.comm_status)
                      .title
                  "
                  popperClass="st-settings-table-el-tooltip__popper"
                  effect="light"
                  placement="top"
                >
                  <i
                    class="material-icons-round"
                    :style="
                      `color:${
                        getDeviceLastCommunicationColorString(
                          row.data.comm_status
                        ).color
                      }`
                    "
                    >signal_cellular_alt</i
                  >
                </el-tooltip>
                <span style="margin-left: 5px">
                  <er-highlight-search-component
                    :highlightStrings="row.data.highlightStrings"
                    :individualRowData="row.data"
                    keyName="code"
                    :withoutHighlightStrings="row.data.code"
                  />
                </span>
              </el-row>
            </template>
            <template v-slot:title="{ row }">
              <div class="alias-container">
                <el-input
                  v-model="row.data.title"
                  :placeholder="tableColumns.title.label_d"
                  :disabled="columnLevelPermissions(row.data._id, 'title', 'MANAGE_DEVICES')"
                  @change="handleChangeInAlias($event, row.data)"
                  size="mini"
                ></el-input>
              </div>
            </template>
            <template v-slot:pond_mothers="{ row }">
              <div class="pm-contianer">
                <el-tag
                  v-for="(pm, index) in getStRowPMs(row.data.pond_mothers)"
                  :key="index"
                  :type="pm.is_master ? 'primary' : 'info'"
                  size="mini"
                >
                  {{ pm.code }}
                </el-tag>
                <el-tag
                  size="mini"
                  v-if="getStRowPMs(row.data.pond_mothers).length === 0"
                >
                  {{ $t("Comn_no_pondmothers") }}
                </el-tag>
                <er-button
                  type="text"
                  btnType="add"
                  :showIcon="true"
                  size="mini"
                  @click="handleAddPms($event, row.data)"
                ></er-button>
              </div>
            </template>
            <template v-slot:mac_address="{ row }">
              {{ getValidMacAddress(row.data) }}
            </template>
            <template v-slot:ip_address="{ row }">
              {{ ftm__formatEmpty(row.data.ip_address) }}
            </template>
            <template v-slot:start_time="{ row }">
              <el-row class="time-field">
                <el-time-picker
                  v-model="row.data.settings.start_time"
                  size="mini"
                  value-format="HH:mm"
                  format="HH:mm"
                  :picker-options="pickerOptions"
                  :placeholder="tableColumns.start_time.label"
                  @change="
                    handleChangeTimeRange($event, row.data, 'start_time')
                  "
                  :disabled="columnLevelPermissions(row.data._id, '', 'MANAGE_SCHEDULES')"
                  :clearable="false"
                ></el-time-picker>
              </el-row>
            </template>
            <template v-slot:end_time="{ row }">
              <el-row class="time-field">
                <el-time-picker
                  v-model="row.data.settings.end_time"
                  size="mini"
                  value-format="HH:mm"
                  format="HH:mm"
                  :picker-options="pickerOptions"
                  :placeholder="tableColumns.end_time.label"
                  @change="handleChangeTimeRange($event, row.data, 'end_time')"
                  :disabled="columnLevelPermissions(row.data._id, '', 'MANAGE_SCHEDULES')"
                  :clearable="false"
                ></el-time-picker>
              </el-row>
            </template>
            <template v-slot:pause_start="{ row }">
              <el-row class="time-field">
                <el-time-picker
                  v-model="row.data.settings.pause_start"
                  size="mini"
                  value-format="HH:mm"
                  format="HH:mm"
                  :picker-options="pauseTimePickerOptions"
                  :placeholder="tableColumns.pause_start.label"
                  @change="
                    handleChangeTimeRange($event, row.data, 'pause_start')
                  "
                  :disabled="columnLevelPermissions(row.data._id, '', 'MANAGE_SCHEDULES')"
                  :clearable="false"
                />
              </el-row>
            </template>
            <template v-slot:pause_end="{ row }">
              <el-row class="time-field">
                <el-time-picker
                  v-model="row.data.settings.pause_end"
                  size="mini"
                  value-format="HH:mm"
                  format="HH:mm"
                  :picker-options="pauseTimePickerOptions"
                  :placeholder="tableColumns.pause_end.label"
                  @change="handleChangeTimeRange($event, row.data, 'pause_end')"
                  :disabled="columnLevelPermissions(row.data._id, '', 'MANAGE_SCHEDULES')"
                  :clearable="false"
                />
              </el-row>
            </template>
            <template v-slot:wifi_signal_RSSI="{ row }">
              <p>
                {{ row.data.wifi_signal_RSSI }}
              </p>
            </template>
            <template v-slot:wifi_connection="{ row }">
              <p>
                {{ row.data.wifi_connection }}
              </p>
            </template>
            <template v-slot:feed_limit="{ row }">
              <div class="table-feed-limit">
                <el-input-number
                  v-model="row.data.settings.feed_limit"
                  :placeholder="tableColumns.feed_limit.label_d"
                  size="mini"
                  :controls="false"
                  @change="handleChangeInFeedLimit($event, row.data)"
                  :disabled="columnLevelPermissions(row.data._id, 'feed_limit', 'MANAGE_SCHEDULES')"
                ></el-input-number>
              </div>
            </template>
            <template v-slot:feeding_level="{ row }">
              <er-select
                :value="feedingLevelValue(row)"
                value-key="_id"
                :placeholder="$t('Comn_feed_level')"
                filterable
                collapse-tags
                size="mini"
                :disabled="columnLevelPermissions(row.data._id, '', 'MANAGE_SCHEDULES')"
                @change="handleChangeInFeedingLevel($event, row.data)"
                popper-class="pm-dropdown"
              >
                <template v-for="level in row.data.settings.feedingLevel">
                  <el-option
                    :key="level.value"
                    :label="$t(level.label)"
                    :value="level.value"
                  ></el-option>
                </template>
              </er-select>
            </template>
          </er-data-tables>
        </el-row>
      </el-row>
    </el-row>
    <Loader v-else-if="loading"></Loader>
    <er-no-ponds :tabData="tabData" :disabled="isReadOnly" v-else />
    <component
      :is="`AddPMToShrimpTalk`"
      :showDialog="dialogVisible"
      :pondPmsData="pondPmsData"
      :stDetails="singleStDetails"
      @close_dialog="closeDialog"
    ></component>

    <component
      :is="`shrimpTalkActionDialog`"
      :showDialog="dialogVisibleofST"
      :stPmModeData="st_Pm_mode"
      @close_dialog="closeDialogST"
    ></component>
  </el-row>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import PondMother from "@/model/pondmother";
import ShrimpTalk from "@/model/shrimptalk";
import Loader from "@/components/base/Loader";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import { ALL_USERS as FEATURE_ALL_USERS } from "@/middleware/featureToggleManager";
import AddPMToShrimpTalk from "./AddPMToShrimpTalk";
import shrimpTalkActionDialog from "./shrimpTalkActionDialog";
import filtersMixin from "@/mixins/filtersMixin";
import {
  MAX_AUTO_E_TIME_SECS,
  MIN_S_TIME_SECS,
  MAX_AUTO_S_TIME_SECS
} from "@/constants/schedule";
import { castSecsHHmmStr } from "../../utils/commonUtils";
import exportFileDownload from "@/mixins/exportFileDownload";
// import { versionCompare } from "@/utils/comparators.js";
export default {
  mixins: [
    errorHandlerMixin,
    filtersMixin,
    userPreferenceMixin,
    exportFileDownload
  ],
  components: {
    Loader,
    AddPMToShrimpTalk,
    shrimpTalkActionDialog
  },
  props: {
    tabData: {
      default: {}
    }
  },
  data: function() {
    return {
      data: {},
      mainPM: "",
      search: "",
      dialogVisible: false,
      dialogVisibleofST: false,
      isPondUnAssign: false,
      pondMotherObj: new PondMother(),
      shrimpTalkObj: new ShrimpTalk(),
      pondsSelected: [],
      ehm__errMessagesObject: new PondMother(),
      selectedPMIds: [],
      singleStDetails: {},
      editPMObj: {},
      tableEditSTObj: {},
      pondPmsData: {},
      selectAllChecked: {},
      selectedHeaders: {},
      selectedSTIds: [],
      pickerOptions: {
        selectableRange: `${castSecsHHmmStr(
          MIN_S_TIME_SECS
        )}:00-${castSecsHHmmStr(MAX_AUTO_E_TIME_SECS)}:00`
      },
      pauseTimePickerOptions: {
        selectableRange: `${castSecsHHmmStr(
          MAX_AUTO_S_TIME_SECS
        )}:00-${castSecsHHmmStr(MAX_AUTO_E_TIME_SECS)}:00`
      },
      loading: false,
      tableProps: {
        // border: true,
        size: "small",
        stripe: true,
        rowKey: this.getRowKey,
        defaultSort: {
          prop: "pond_title",
          order: "ascending"
        },
        height: "calc(100vh - var(--table-gutter))"
      },

      disabled: false,
      query: {
        get_all: true,
        order_by: null,
        order_type: null,
        un_assigned_only: false,
        location_id: null
      },
      editStObj: {},
      feedingLevel: [
        {
          label: "Comn_normal",
          value: 0
        },
        {
          label: "Comn_economical",
          value: 1
        },
        {
          label: "Comn_aggressive",
          value: 2
        },
        {
          label: "Comn_super_aggressive",
          value: 3
        }
      ],
      selectedColumns: [],
      st_Pm_mode: {}
    };
  },
  mounted() {
    this.initComponent();
    this.initColumnSelector();
  },
  computed: {
    ...mapGetters({
      PondMothers: "pondmother/getPondMothers",
      mapPondIdPond: "pond/getMapPondidPond",
      mapStIdSt: "shrimptalk/getMapStidSt",
      Ponds: "pond/getPonds",
      getPmIdPm: "pondmother/getPmIdPm",
      getMapPmIdMode: "pondmother/getMapPmIdMode",
      ShrimpTalks: "shrimptalk/getShrimpTalks",
      getUserId: "user/getUserId"
    }),
    ...mapGetters("user", {
      getPreferredUnits: "getPreferredUnits",
      isReadOnly: "isReadOnly",
      getCurrUserLocation: "getCurrUserLocation",
      getPermissions: 'getPermissions',
      getUserProfile: "getUserProfile"
    }),
    ...mapGetters("pond", {
      Ponds: "getPonds"
    }),
    handlePermissions() {
      return this.getPermissions;
    },
    stPonds() {
      return new Set(this.dataValues.map(({ pond_id }) => pond_id));
    },
    getValidMacAddress() {
      return ({ settings }) => {
        const mac_address = settings.mac_address;
        const pattern = /^(([a-fA-F0-9]{2}-){5}[a-fA-F0-9]{2}|([a-fA-F0-9]{2}:){5}[a-fA-F0-9]{2}|([0-9A-Fa-f]{4}\.){2}[0-9A-Fa-f]{4})?$/g;
        const regEngine = new RegExp(pattern);
        const result = regEngine.test(mac_address);
        if (result) {
          return mac_address;
        }
        return "--";
      };
    },

    getStRowPMs() {
      return (pms) => {
        return this.$lodash
          .cloneDeep(pms)
          .sort((a, b) => (b.is_master || 0) - (a.is_master || 0))
          .filter((x) => x._id);
      };
    },
    getSelectedPondST() {
      return this.dataValues.filter(({ pond_id }) =>
        this.pondsSelected.includes(pond_id)
      );
    },
    selectedPondPMs: function() {
      return this.pondsSelected
        .map((pond) => this.mapPondIdPond.get(pond).pond_mothers)
        .flat(1);
    },
    selectedPondPMIds: function() {
      return this.selectedPondPMs.map((pm) => pm._id);
    },
    tableColumns() {
      return {
        pond_title: {
          prop: "pond_title",
          key: "pond_title",
          label: "Comn_pond_name",
          label_d: this.$t("Comn_pond_name"),
          fixed: true,
          minWidth: "140",
          default: true,
          featureAllowedUsers: FEATURE_ALL_USERS,
          sortable: "custom",
          searchProperty: "pond_title",
          enableSearch: true
        },
        code: {
          prop: "st_sort_key",
          key: "code",
          label: "Shrimptalk_id",
          label_d: this.$t("Shrimptalk_id"),
          default: true,
          sortable: "custom",
          featureAllowedUsers: FEATURE_ALL_USERS,
          minWidth: "130",
          searchProperty: "code",
          enableSearch: true
        },
        ip_address: {
          prop: "ip_address",
          key: "ip_address",
          label: "Comn_ip_addr",
          label_d: this.$t("Comn_ip_addr"),
          sortable: true,
          default: false,
          featureAllowedUsers: FEATURE_ALL_USERS,
          width: "130",
          searchProperty: "ip_address",
          enableSearch: true
        },
        mac_address: {
          prop: "mac_address",
          key: "mac_address",
          label: "Comn_mac_address",
          label_d: this.$t("Comn_mac_address"),
          sortable: true,
          default: false,
          featureAllowedUsers: FEATURE_ALL_USERS,
          width: "150",
          searchProperty: "mac_address",
          enableSearch: true
        },
        wifi_connection: {
          prop: "wifi_connection",
          key: "wifi_connection",
          label: "WiFi SSID",
          label_d: "WiFi SSID",
          sortable: "custom",
          default: false,
          featureAllowedUsers: FEATURE_ALL_USERS,
          minWidth: "120",
          searchProperty: "wifi_connection",
          enableSearch: true
        },
        wifi_signal_RSSI: {
          prop: "wifi_signal_RSSI",
          key: "wifi_signal_RSSI",
          label: "wifi_signal",
          label_d: this.$t("wifi_signal"),
          sortable: true,
          default: false,
          featureAllowedUsers: FEATURE_ALL_USERS,
          minWidth: "120"
        },
        pond_mothers: {
          prop: "pond_mothers",
          key: "pond_mothers",
          label: "PM_pondmothers",
          label_d: this.$t("PM_pondmothers"),
          minWidth: "250",
          sortable: true,
          featureAllowedUsers: FEATURE_ALL_USERS,
          default: true,
          searchProperty: "pond_mothers.code",
          enableSearch: true
        },
        start_time: {
          prop: "settings.start_time",
          key: "start_time",
          label: "PM_start_time",
          label_d: this.$t("PM_start_time"),
          sortable: false,
          default: true,
          featureAllowedUsers: FEATURE_ALL_USERS,
          minWidth: "140"
        },
        end_time: {
          prop: "settings.end_time",
          key: "end_time",
          label: "PM_end_time",
          label_d: this.$t("PM_end_time"),
          sortable: false,
          default: true,
          featureAllowedUsers: FEATURE_ALL_USERS,
          minWidth: "140"
        },
        pause_start: {
          prop: "settings.pause_start",
          key: "pause_start",
          label: "PM_pause_start",
          label_d: this.$t("PM_pause_start"),
          sortable: false,
          default: false,
          featureAllowedUsers: FEATURE_ALL_USERS,
          minWidth: "140"
        },
        pause_end: {
          prop: "settings.pause_end",
          key: "pause_end",
          label: "PM_pause_end",
          label_d: this.$t("PM_pause_end"),
          sortable: false,
          default: false,
          featureAllowedUsers: FEATURE_ALL_USERS,
          minWidth: "140"
        },
        feed_limit: {
          prop: "settings.feed_limit",
          key: "feed_limit",
          label: "PM_day_feed",
          label_d: this.$t("PM_day_feed"),
          sortable: false,
          sub_label: `(${this.$tc(this.upm__feedUnits, 1)})`,
          default: true,
          featureAllowedUsers: FEATURE_ALL_USERS,
          minWidth: "140"
        },
        feeding_level: {
          prop: "feeding_level",
          key: "feeding_level",
          label_d: this.$t("Comn_feeding_level"),
          label: "Comn_feeding_level",
          sortable: false,
          default: false,
          featureAllowedUsers: FEATURE_ALL_USERS,
          minWidth: "180",
          searchProperty: "feeding_level",
          enableSearch: true
        },
        title: {
          prop: "title",
          key: "title",
          label: "Comn_alias_name",
          label_d: this.$t("Comn_alias_name"),
          sortable: "custom",
          default: true,
          featureAllowedUsers: FEATURE_ALL_USERS,
          minWidth: "120",
          searchProperty: "title",
          enableSearch: true
        }
      };
    },
    downloadColumns() {
      return [
        {
          header: this.tableColumns.pond_title.label_d,
          dataKey: "pond_title",
          emptyValue: "--",
          name: "pond_name",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.code.label_d,
          dataKey: "code",
          emptyValue: "--",
          name: "code",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.pond_mothers.label_d,
          dataKey: (value) => {
            return this.ftm__formatEmpty(
              value.pond_mothers.map((x) => x.code).join(",")
            );
          },
          emptyValue: "--",
          name: "pond_mothers",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.start_time.label_d,
          dataKey: "settings.start_time",
          name: "start_time",
          emptyValue: "--",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.end_time.label_d,
          dataKey: "settings.end_time",
          name: "end_time",
          emptyValue: "--",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },

        {
          header: this.tableColumns.feed_limit.label_d,
          dataKey: "settings.feed_limit",
          name: "feed_limit",
          emptyValue: "--",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.ip_address.label_d,
          dataKey: "settings.ip_address",
          name: "ip_address",
          emptyValue: "--",
          skipColumn: this.filterColumn("ip_address"),
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.mac_address.label_d,
          dataKey: (value) => {
            return this.getValidMac(value.settings.mac_address);
          },
          name: "mac_address",
          emptyValue: "--",
          skipColumn: this.filterColumn("mac_address"),
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.wifi_connection.label_d,
          dataKey: "wifi_connection",
          name: "wifi_connection",
          emptyValue: "--",
          skipColumn: this.filterColumn("wifi_connection"),
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.wifi_signal_RSSI.label_d,
          dataKey: "sensor_values.wifi_signal_RSSI",
          name: "wifi_signal_RSSI",
          emptyValue: "--",
          canBeNegative: true,
          skipColumn: this.filterColumn("wifi_signal_RSSI"),
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.pause_start.label_d,
          dataKey: "settings.pause_start",
          name: "pause_start",
          emptyValue: "--",
          skipColumn: this.filterColumn("pause_start"),
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.pause_end.label_d,
          dataKey: "settings.pause_end",
          name: "pause_end",
          emptyValue: "--",
          skipColumn: this.filterColumn("pause_end"),
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.feeding_level.label_d,
          dataKey: (value) => {
            return this.$t(this.getfeedingLevel(value.settings.feeding_level));
          },
          name: "feeding_level",
          emptyValue: "--",
          skipColumn: this.filterColumn("feeding_level"),
          formatters: [],
          xcelcolumnWidth: 15
        },

        {
          header: this.tableColumns.title.label_d,
          dataKey: "title",
          name: "title",
          emptyValue: "--",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        }
      ];
    },
    computedColumns: function() {
      const columns = this.$lodash.cloneDeep(this.tableColumns);
      Object.keys(columns).forEach((x) => {
        columns[x].label = this.$t(columns[x].label);
      });
      return columns;
    },
    dataValues() {
      const tableData = Object.values(this.data);
      tableData.map((item, i) => {
        if (item.user_id === "5f74984140f3e1412d1de9cd" ||
            item.user_id === "5e5cfb322c599d13d25b607e" ||
            item.user_id === "5f098445be50fddb616e47c9") {
          item.settings.feedingLevel = [
            {
              label: "Comn_normal",
              value: 0
            },
            {
              label: "Comn_economical",
              value: 1
            },
            {
              label: "Comn_aggressive",
              value: 2
            },
            {
              label: "Comn_super_aggressive",
              value: 3
            }
          ];
        } else {
          item.settings.feedingLevel = [
            {
              label: "Comn_normal",
              value: 0
            },
            {
              label: "Comn_economical",
              value: 1
            },
            {
              label: "Comn_aggressive",
              value: 2
            }
          ];
        }
      });
      return tableData;
    },
    isTableEditted() {
      return Object.keys(this.tableEditSTObj).length > 0;
    },
    location() {
      return this.$store.getters["user/getCurrUserLocation"];
    },
    sortMethods() {
      return {
        pond_title: this.$commonUtils.alphaNumericComparator,
        st_sort_key: this.$commonUtils.alphaNumericComparator,
        wifi_connection: this.$commonUtils.alphaNumericComparator,
        title: this.$commonUtils.alphaNumericComparator
      };
    },
    getFeedLowerLimit() {
      if (this.upm__feedUnits === "kg") {
        return 20;
      } else {
        return 44;
      }
    },
    getFeedUpperLimit() {
      if (this.upm__feedUnits === "kg") {
        return 400;
      } else {
        return 441;
      }
    }
  },
  methods: {
    ...mapActions({
      fetchAllPondMothers: "pondmother/fetchAllPondMothers",
      fetchAllShrimpTalks: "shrimptalk/fetchAllShrimpTalks",
      fetchAllPonds: "pond/fetchAllPonds",
      updateShrimpTalkDetails: "shrimptalk/updateShrimpTalkDetails"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handelSearchChange() {
      if (this.search !== '') {
        this.mixPanelEventGenerator({ eventName: "Settings - ShrimpTalk - Search" });
      }
    },
    handelSaveColumns() {
      this.mixPanelEventGenerator({ eventName: "Settings - ShrimpTalk - Column Selector - Save" });
    },

    handleOpenDialogofST() {
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        this.dialogVisibleofST = true;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    async closeDialogST(event, input) {
      this.dialogVisibleofST = false;
      if (input === "refreshComponent") {
        this.mixPanelEventGenerator({ eventName: "Settings - ShrimpTalk - Group Settings - Save" });
        await this.initComponent();
      }
    },
    getValidMac(mac_address) {
      const pattern = /^(([a-fA-F0-9]{2}-){5}[a-fA-F0-9]{2}|([a-fA-F0-9]{2}:){5}[a-fA-F0-9]{2}|([0-9A-Fa-f]{4}\.){2}[0-9A-Fa-f]{4})?$/g;
      const regEngine = new RegExp(pattern);
      const result = regEngine.test(mac_address);
      if (result) {
        return mac_address;
      }
      return "--";
    },
    getWifiRSSIDetails(sensorObj) {
      return sensorObj && sensorObj.wifi_signal_RSSI;
    },
    getfeedingLevel(input) {
      const feeding_levelObj = {
        1: "Comn_economical",
        2: "Comn_aggressive",
        0: "Comn_normal"
      };
      return feeding_levelObj[input];
    },

    handleTableDataDownload(downloadtype) {
      if (downloadtype === "Comn_download_as_pdf") {
        this.pdfdownload();
      } else {
        this.xceldownload();
      }
      this.mixPanelEventGenerator({ eventName: "Settings - ShrimpTalk - Download" });
    },
    xceldownload() {
      this.exp__Download(
        this.$refs.tableST.$refs.dataTables.sortedData,
        this.downloadColumns,
        "xcel"
      );
    },
    pdfdownload() {
      this.exp__Download(
        this.$refs.tableST.$refs.dataTables.sortedData,
        this.downloadColumns,
        "pdf"
      );
    },
    initColumnSelector() {
      const columnObj = Object.keys(this.tableColumns).reduce((acc, curr) => {
        acc[curr] = this.tableColumns[curr];
        return acc;
      }, {});
      this.selectedHeaders = Object.assign({}, columnObj);
    },
    getDeviceLastCommunicationColorString(input) {
      const objMap = {
        ON_LINE: {
          color: "#33A02C",
          title: this.$t("online")
        },
        OFF_LINE: { color: "#E31A1C", title: this.$t("offline") }
      };
      if (input) {
        return objMap.ON_LINE;
      } else {
        return objMap.OFF_LINE;
      }
    },
    getRowKey(row) {
      return row.shrimp_talk_id;
    },
    getPond(pond_id) {
      if (this.mapPondIdPond && this.mapPondIdPond.get(pond_id)) {
        return this.mapPondIdPond.get(pond_id);
      } else {
        return { pond_mothers: [] };
      }
    },
    async initComponent() {
      this.pondsSelected = [];
      this.selectedPMIds = [];
      this.pondMotherObj = new PondMother();
      await this.loadSTList();
      this.upm__setDataKeys("code", "title");
    },
    loadSTList: async function() {
      this.loading = true;
      try {
        this.query.status = ["ACTIVE"];
        await Promise.all([
          this.fetchAllPonds({
            location_id: this.location._id,
            status: ["ACTIVE", "INACTIVE"],
            exclude: ["geometry"],
            get_all: true
          }),
          this.fetchAllPondMothers({
            location_id: this.location._id,
            get_all: true
          }),
          this.fetchAllShrimpTalks({
            location_id: this.location._id,
            get_all: true
          })
        ]);
        const pondPmsMap = this.Ponds.reduce((acc, pond) => {
          acc[pond._id] = {};
          pond.pond_mothers.forEach((pm) => {
            acc[pond._id][pm._id] = this.$lodash.cloneDeep(
              this.getPmIdPm.get(pm._id)
            );
          });
          return acc;
        }, {});
        this.pondPmsData = Object.assign({}, pondPmsMap);
        const clonedSts = this.$lodash.cloneDeep(this.ShrimpTalks);
        clonedSts.forEach((st) => {
          const tempFeedLimit = st.settings.feed_limit;
          st.settings.feed_limit = Math.round(
            Number(
              this.upm__convertUnits(
                tempFeedLimit,
                this.upm__backendUnits.feed_units,
                this.upm__feedUnits
              )
            )
          );
          st.title = st.title ? st.title : st.code;
          st.pond_title = this.getPondName(st.pond_id);
          st.st_sort_key = `${st.code}`;
          st.pond_mothers = st.pond_mothers.filter(
            (pm) => this.pondPmsData[st.pond_id][pm._id]
          );
          st.ip_address = st.settings ? st.settings.ip_address : "--";
          st.mac_address = st.settings ? st.settings.mac_address : "--";
          st.wifi_signal_RSSI = st.sensor_values
            ? st.sensor_values.wifi_signal_RSSI
            : "--";
          st.wifi_connection = st.sensor_values
            ? st.sensor_values.wifi_connection
            : "--";
          this.$set(this.data, st._id, st);
          this.getHybridModeChecked(st.pond_mothers, st._id)
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.tableST) {
            this.$refs.tableST.$refs.dataTables.$refs.elTable.doLayout();
          }
        });
        this.loading = false;
      }
    },
    getHybridModeChecked(pondMothers, stId) {
      // this.st_Pm_mode[stId] = pondMothers.map((pm) => {
      //   return pm.is_master ? this.getPmIdPm.get(pm._id).mode : '';
      // }).includes("HYBRID");
      this.st_Pm_mode[stId] = pondMothers.map((pm) => {
        return this.getPmIdPm.get(pm._id).mode;
      });
      if (this.st_Pm_mode[stId].length > 0) {
        this.st_Pm_mode[stId] = this.st_Pm_mode[stId].some(val => val === "HYBRID")
      } else {
        this.st_Pm_mode[stId] = false
      }
    },
    columnLevelPermissions(stId, column = '', permission = '') {
      if (this.getUserProfile.permissions.length > 0 && this.handlePermissions[permission]) {
        return column === 'title' ? false : !this.handlePermissions[permission].UPDATE;
      } else if (column === 'feed_limit' || column === 'title') {
        return this.selectedSTIds.indexOf(stId) > -1;
      } else {
        return this.st_Pm_mode[stId] || this.selectedSTIds.indexOf(stId) > -1;
      }
    },
    feedingLevelValue(row) {
      return this.st_Pm_mode[row.data._id] ? '' : row.data.settings.feeding_level;
    },
    getPondName(pondId) {
      if (pondId !== "") {
        return (this.mapPondIdPond.get(pondId) || { title: "Not Found" }).title;
      }
    },
    handlePMChangeInTable(event, pm) {
      if (this.editStObj[pm._id] === null) {
        this.$set(this.editStObj, pm._id, pm);
      }
    },
    handleChangeTimeRange(changedTimeRange, st, field_name) {
      if (this.isCurrValSameAsBkVal(st, `settings.${field_name}`)) {
        delete this.editStObj[st._id];
        return;
      }
      this.editStObj[st._id] = st;
    },
    handleChangeInAlias(nValue, st) {
      if (this.isCurrValSameAsBkVal(st, "title")) {
        delete this.editStObj[st._id];
        return;
      }
      st.title = st.title.trim();
      this.editStObj[st._id] = st;
    },
    handleChangeInFeedLimit(nValue, st) {
      if (this.isCurrValSameAsBkVal(st, "settings.feed_limit")) {
        delete this.editStObj[st._id];
        return;
      }
      this.editStObj[st._id] = st;
    },
    handleChangeInFeedingLevel(nValue, st) {
      if (this.isCurrValSameAsBkVal(st, "settings.feeding_level")) {
        delete this.editStObj[st._id];
        return;
      }
      st.settings.feeding_level = nValue;
      this.editStObj[st._id] = st;
    },
    isCurrValSameAsBkVal(prsntST, path) {
      const prsntSTId = prsntST._id;
      const bkST = this.mapStIdSt.get(prsntSTId);
      const currValue = this.$lodash.get(prsntST, path);
      const bkValue = this.$lodash.get(bkST, path);
      if (currValue === bkValue && this.editStObj[prsntSTId]) {
        return true;
      }
      return false;
    },
    filterColumn(value) {
      const booleanValue = this.selectedColumns.includes(value);
      return !booleanValue;
    },
    handleChangeColumnSelection(selectedColumns) {
      this.selectedColumns = selectedColumns;

      this.$nextTick(() => {
        if (this.$refs.tableST) {
          this.$refs.tableST.$refs.dataTables.$refs.elTable.doLayout();
        }
      });
    },
    handleAddPms(event, stDetails) {
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        this.singleStDetails = stDetails;
        this.dialogVisible = true;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      }
    },
    async closeDialog(event, input) {
      this.dialogVisible = false;
      if (input === "refresh") {
        this.mixPanelEventGenerator({ eventName: "Settings - ShrimpTalk - Add PondMother - Save" });
        await this.initComponent();
      }
    },
    validatePayload(datum, startTimeSecs, endTimeSecs, inSTTitle, STTitle) {
      const errors = [];
      const { title, code } = datum;
      if (!startTimeSecs) {
        errors.push({
          message: `${this.$t("PM_start_time")} can't be empty ${inSTTitle}`
        });
      } else if (!endTimeSecs) {
        errors.push({
          message: `${this.$t("PM_stop_time")} can't be empty ${inSTTitle}`
        });
      } else if (endTimeSecs <= startTimeSecs) {
        errors.push({
          message: `${this.$tc(
            "PM_stop_time_must_greater_start_time"
          )} ${inSTTitle}`
        });
      } else if (!title.trim()) {
        errors.push({
          message: `${this.$t("Alias_name_can_not_be_empty")} ${this.$t(
            "Comn_for"
          )} ${code}`
        });
      } else if (datum.pause_start !== datum.pause_end) {
        const pauseStartTimeSecs = this.$commonUtils.timeStrHHmmVal(
          datum.pause_start
        );
        const pauseEndTimeSecs = this.$commonUtils.timeStrHHmmVal(
          datum.pause_end
        );
        if (pauseStartTimeSecs <= startTimeSecs) {
          errors.push({
            message: `${this.$tc(
              "PM_pause_stop_time_must_greater_start_time"
            )} ${inSTTitle}`
          });
        } else if (pauseEndTimeSecs >= endTimeSecs) {
          errors.push({
            message: `${this.$tc(
              "PM_pause_end_time_must_smaller_end_time"
            )} ${inSTTitle}`
          });
        } else if (pauseEndTimeSecs <= pauseStartTimeSecs) {
          errors.push({
            message: `${this.$tc(
              "PM_pause_end_time_must_greater_pause_start_time"
            )} ${inSTTitle}`
          });
        }
      }
      if (datum.feed_limit < this.getFeedLowerLimit) {
        errors.push({
          message: `${this.$tc("Comn_must_greater_than_or_equal", 1, {
            field: this.tableColumns.feed_limit.label_d,
            threshold: this.getFeedLowerLimit
          })}${STTitle}`
        });
      }
      if (datum.feed_limit > this.getFeedUpperLimit) {
        errors.push({
          message: `${this.$tc("Comn_must_less_than_or_equal", 2, {
            field: this.tableColumns.feed_limit.label_d,
            threshold: this.getFeedUpperLimit
          })}${STTitle}`
        });
      }
      return errors;
    },
    async submitUpdatedDetails() {
      let errors = [];
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        this.ehm__unhandledErrorMessageV2 = "";
        this.loading = true;
        const payLoadObj = {};
        const selectedSTs = this.getSelectedPondST
          .filter(({ _id }) => this.selectedSTIds.includes(_id))
          .map((st) => JSON.parse(JSON.stringify(st)));
        if (selectedSTs.length > 0) {
          selectedSTs.forEach(
            (st) => (st.settings = this.shrimpTalkObj.settings)
          );
          selectedSTs.forEach((st) => {
            this.editStObj[st._id] = st;
          });
        }
        Object.values(this.editStObj).forEach((st) => {
          if (st != null && typeof st !== "undefined") {
            // st.title = st.title || st.code;
            st.status = "ACTIVE";
            const pms = st.pond_mothers
              .filter(({ _id }) => !!_id)
              .map(({ _id, code }) => {
                return { _id, code, is_master: st.main_pond_mother_id === _id };
              });

            if (st.settings.pause_start || st.settings.pause_end) {
              if (!st.settings.pause_start) {
                throw {
                  type: "FAIL_TO_SAVE",
                  errors: [
                    {
                      message: `${this.$t("PM_pause_start")} ${this.$t(
                        "Comn_is_required"
                      )} ${st.title}`
                    }
                  ]
                };
              } else if (!st.settings.pause_end) {
                throw {
                  type: "FAIL_TO_SAVE",
                  errors: [
                    {
                      message: `${this.$t("PM_pause_end")} ${this.$t(
                        "Comn_is_required"
                      )} ${this.$t("Comn_in")} ${st.title}`
                    }
                  ]
                };
              }
              payLoadObj[st._id] = {
                pond_mothers: pms,
                // title: st.title || st.code,
                title: st.title,
                code: st.code,
                shrimp_talk_id: st._id,
                start_time: st.settings.start_time
                  ? st.settings.start_time.padEnd(8, ":00")
                  : undefined,
                end_time: st.settings.end_time
                  ? st.settings.end_time.padEnd(8, ":00")
                  : undefined,
                pause_start: st.settings.pause_start.padEnd(8, ":00"),
                pause_end: st.settings.pause_end.padEnd(8, ":00"),
                feed_limit:
                  this.upm__feedUnits === "lb"
                    ? Math.round(st.settings.feed_limit / 2.2)
                    : st.settings.feed_limit,
                feeding_level: st.settings.feeding_level
              };
            } else {
              payLoadObj[st._id] = {
                // title: st.title || st.code,
                title: st.title,
                code: st.code,
                shrimp_talk_id: st._id,
                start_time: st.settings.start_time
                  ? st.settings.start_time.padEnd(8, ":00")
                  : undefined,
                end_time: st.settings.end_time
                  ? st.settings.end_time.padEnd(8, ":00")
                  : undefined,
                feed_limit:
                  this.upm__feedUnits === "lb"
                    ? Math.round(st.settings.feed_limit / 2.2)
                    : st.settings.feed_limit,
                feeding_level: st.settings.feeding_level
              };
            }
          }
        });
        const payload = Object.values(payLoadObj);
        this.ehm__errorMessagesObject = new ShrimpTalk();
        if (payload.length === 0) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: this.$t("Comn_no_changes_found", {
                  item: this.$t("Comn_shrimptalk")
                })
              }
            ]
          };
        }
        payload.forEach((datum) => {
          const startTimeSecs = datum.start_time
            ? this.$commonUtils.timeStrHHmmVal(datum.start_time)
            : undefined;
          const endTimeSecs = datum.end_time
            ? this.$commonUtils.timeStrHHmmVal(datum.end_time)
            : undefined;
          const inSTTitle = `${this.$t("Comn_in")} ${datum.title}`;
          const STTitle = `${this.$t("Comn_kgs")} ${this.$t("Comn_for")} ${
            datum.title
          }`;
          errors = [
            ...errors,
            ...this.validatePayload(
              datum,
              startTimeSecs,
              endTimeSecs,
              inSTTitle,
              STTitle
            )
          ];
        });
        if (errors.length > 0) {
          throw {
            type: "FAIL_TO_SAVE",
            errors
          };
        }
        if (payload.length > 0) {
          await this.updateShrimpTalkDetails(payload);
          this.mixPanelEventGenerator({ eventName: "Settings - ShrimpTalk - Save" });
          this.editStObj = {};
          this.ehm__errorMessagesObject = new ShrimpTalk();
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: this.$t("Shrimp_talk_details_updated_sucess"),
            duration: 5000,
            type: "success"
          });
          await this.initComponent();
        }
      } catch (err) {
        if (err.response && err.response.data.message) {
          errors.push({ message: err.response.data.message });
          this.ehm__errorFailToSave(errors);
        } else if (err.response && err.response.data.errors != null) {
          err.response.data.errors.details.forEach((el, index) => {
            errors.push({ message: el.message });
          });
          this.ehm__errorFailToSave(errors);
        } else {
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.st-dropdown {
  .el-select-dropdown__item {
    // font-size: 11px;
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
    // height: 20px;
    display: flex;
    align-items: center;
  }
  .item-name {
    min-width: 50px;
  }
  .main-item-radio,
  .main-item-header {
    margin-left: auto;
  }
}
.cancel-btn {
  cursor: pointer;
  background: rgba(229, 236, 243, 0.5);
  border: 1px solid #acc9f4;
  box-sizing: border-box;
  border-radius: 4px;
  width: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}
.disabled {
  pointer-events: none;
  opacity: 0.4;
}
.post-header-shrimp-talk {
  padding: 10px;
  width: 100%;
  display: flex;
  // justify-content: space-evenly;

  .ponds-container {
    width: 120px;
  }
  .st-container {
    width: 130px;
  }
}
.shrimp-talk-settings-pondlogs {
  @include responsiveProperty(--table-gutter, 212px, 212px, 220px);
  $input_width: 100%;

  .er-data-tables .el-table .el-select {
    @include responsiveProperty(width, 120px, 120px, 150px);
  }

  .action-icon {
    color: black;
    opacity: 0.3;
    cursor: pointer;
    @include icon-size(icon-font);
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
  .el-radio__label {
    padding-left: unset;
  }
  .el-radio__input.is-checked .el-radio__inner {
    border-color: #0a2463;
    background: #0a2463;
  }
  @include er-checkbox-checked;
  .st-settings-table {
    padding: 0 12px;
  }
  .el-tag--primary {
    border-radius: 20px;
    color: white;
    background-color: $selected-color;
    border-color: $selected-color;
  }
  .el-tag--mini {
    border-radius: 20px;
  }
  .filters {
    display: flex;
    flex-direction: row;
    justify-content: center;
    & > div:nth-child(n + 1) {
      margin-right: 10px;
    }
  }
  .el-input-number {
    width: 100%;
  }
  .el-select__tags {
    // max-width: 170px !important;
  }
  // .el-table__fixed {
  //   @include responsiveProperty(
  //     height,
  //     405px !important,
  //     536px !important,
  //     741px !important
  //   );
  // }

  .table {
    margin: 10px;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
  .el-input--suffix.el-date-editor--time .el-input__inner {
    padding-right: 20px;
  }
  .legend-row__item {
    margin-right: auto !important;
    height: 20px;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    .color-indicator {
      background-color: $selected-color;
      @include responsiveProperty(
        width,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
      @include responsiveProperty(
        height,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
      margin-right: 5px;
      border-radius: 3px;
    }
    .legend-row__label {
      @include responsiveProperty(
        font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
      font-weight: 400;
    }
  }
  .el-pagination__sizes {
    .el-input--mini .el-input__inner {
      height: 22px;
      line-height: 22px;
    }
  }
  .el-select__tags-text {
    color: #0a2463;
    background-color: #f2f0f9;
  }
  .el-select .el-tag.el-tag--info .el-tag__close {
    color: #909399;
    pointer-events: none;
    display: none;
  }

  // .el-dialog {
  //   @include responsiveProperty(width, 25%, 25%, 20%, true);
  //   border-radius: 12px;
  //   .el-dialog__body {
  //     height: 240px;
  //   }
  // }
  .pm-tag-list {
    display: flex;
    flex-direction: row;
    margin: 10px;
    box-sizing: border-box;
  }
  .pagination-bar {
    margin-bottom: 10px;
  }
  .error-message-tag {
    height: unset;
    white-space: pre-line;
    margin: auto;
    margin: 20px 0px;
  }
  .st-pm-list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 200px;
    margin-top: 10px;
    .el-checkbox-group {
      column-count: 1;
    }
    $margin-with-item: 70px;
    .er-list-item {
      @include normal-text;
      flex-direction: row;
      display: flex;
      align-items: center;
      padding: 0 20px;
      position: relative;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #606266;
      height: 34px;
      line-height: 34px;
      box-sizing: border-box;
      cursor: pointer;
      .el-radio__input {
        margin-left: $margin-with-item;
      }
    }
    .stick {
      position: sticky;
      top: 0px;
      z-index: 5;
      background-color: white;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .el-checkbox {
      margin-right: unset;
      .el-checkbox__label {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .st-settings-table {
    .el-table {
      overflow-x: auto;
    }
    .pm-contianer {
      .el-select {
        @include responsiveProperty(width, 160px, 160px, 200px);
      }
    }
    .time-field {
      .el-input__suffix {
        padding-right: 10px;
      }
      .el-input {
        @include responsiveProperty(width, 90px, 110px, 110px);
      }
    }
    .table-feed-limit {
      .el-input {
        @include responsiveProperty(width, 90px, 110px, 110px);
      }
    }
    .alias-container {
      .el-input {
        @include responsiveProperty(width, 90px, 110px, 110px);
      }
    }
  }
}
.st-settings-table-el-tooltip__popper {
  border: 1px solid #606266 !important;
  color: #606266 !important;
}

.st-settings-table-el-tooltip__popper[x-placement^="top"] .popper__arrow {
  border-top-color: #606266 !important;
}
</style>
